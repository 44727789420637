import React, { useState } from "react";
import axios from "axios";
import zxcvbn from "zxcvbn"; // Library for password strength estimation npm install zxcvbn
import MessageComponent from "../message/MessageComponent";
import "./CreateUser.css";

function Creatuser() {
  const [message, setMessage] = useState(null);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);

  const createUser = async () => {
    try {
      // Check password length
      if (password.length < 6) {
        setMessage({
          type: "error",
          text: "Password must be at least 6 characters long",
        });
        return;
      }

      // Check password strength
      const passwordScore = zxcvbn(password).score;
      if (passwordScore < 3) {
        setMessage({ type: "error", text: "Please use a stronger password" });
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API_URL}api/users`;
      const existingUsersResponse = await axios.get(apiUrl);
      const existingUsers = existingUsersResponse.data;

      const isUsernameExists = existingUsers.some(
        (user) => user.username === username
      );
      const isEmailExists = existingUsers.some((user) => user.email === email);

      if (isUsernameExists || isEmailExists) {
        setMessage({ type: "error", text: "Account already exists" });
        return;
      }
      // Check if the number of users is greater than or equal to 4
      if (existingUsers.length >= 4) {
        setMessage({ type: "error", text: "Maximum user limit reached" });
        return;
      }
      // If username and email are unique, proceed to create the user
      const api = `${process.env.REACT_APP_API_URL}api/Samusercreation`;
      await axios.post(api, { username, password, email, firstname });
      // console.log('User created:', response.data);
      setMessage({ type: "success", text: "Account created successfully" });
    } catch (error) {
      setMessage({ type: "error ", text: `An error occurred ${error}` });
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const score = zxcvbn(e.target.value).score;
    setPasswordStrength(score);
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
      case 1:
        return "red";
      case 2:
        return "yellow";
      case 3:
      case 4:
        return "green";
      default:
        return "";
    }
  };

  return (
    <div className="user_account">
      <form className="Admin-form">
        <p className="tit__1">Create User</p>
        <p className="Expensivemessage">
          {" "}
          <strong className="ExpensiveIcon">* </strong> Maximum{" "}
          <u className="ExpensiveLine">four</u> users are allowed for creation
        </p>
        <div className="Admin-form-create-grid">
          <label>
            {" "}
            <input
              className="input"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="off"
            />{" "}
          </label>
          <label>
            {" "}
            <input
              className="input"
              type="text"
              placeholder="first name"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              autoComplete="off"
            />{" "}
          </label>
        </div>

        <label>
          {" "}
          <input
            className="input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            style={{
              backgroundImage: `linear-gradient(to left, ${getPasswordStrengthColor()} -50%, transparent 60%)`,
            }}
            autoComplete="off"
          />{" "}
        </label>
        <label>
          {" "}
          <input
            className="input"
            type="email"
            placeholder="Email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />{" "}
        </label>

        <button className="Adminsubmit" onClick={createUser}>
          Create User
        </button>
        {message && (
          <MessageComponent type={message.type} text={message.text} />
        )}
      </form>
    </div>
  );
}

export default Creatuser;
