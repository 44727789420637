// for login

import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  // const [successMessage, setSuccessMessage] = useState(null);

  const loginAction = async (data) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/Adminlogin`;
      // const response = await fetch("https://samrawitfoundation.org.et/#/Adminlogin", {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.success && res.data.token) {
        setUser(res.data.username); // Assuming res.data.user contains the username
        setToken(res.data.token);
        localStorage.setItem("site", res.data.token); // Store the token in localStorage
        // sessionStorage.setItem('smFoundation', res.data.token) // Store the token in sessionStorage
        navigate("/AdminPage");
        return;
      }
      throw new Error(res.message || "Login failed");
    } catch (err) {
      setError("Please try again.");
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {error && (
        <div className="LoginContainer container red errorbottom">
          Error: {error}
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
