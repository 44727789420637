import React from 'react'
import "./home.css"


function Home() {


  return (
    <section className="LandingPage_para" id="home" >
      <div className="pahe_para container " >

        <div >
          <div className="home__content sideByside" >

          </div>
        </div>

      </div>
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00cba9" fill-opacity="1" d="M0,288L80,277.3C160,267,320,245,480,240C640,235,800,245,960,240C1120,235,1280,213,1360,202.7L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg> */}
    </section>

  )
}

export default Home