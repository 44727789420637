import React, { useState, useEffect } from "react";
import "./Objective.css";
import axios from "axios";

const Objectives = () => {
  const [data, setData] = useState([]);
  const [headline, setHeadline] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/ourCorevalues`;
        const api = `${process.env.REACT_APP_API_URL}api/ourCorevaluesTitle`;
        const result = await axios.get(apiUrl);
        const titleResult = await axios.get(api);

        setData(result.data);
        setHeadline(titleResult.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="the_objective container">
      <div className="firstcontents objective_title">
        {Array.isArray(headline) &&
          headline.length > 0 &&
          headline[0].title === "N" ? (
          <h1 className="h1">Our Core Values</h1>
        ) : headline.length > 0 ? (
          headline.map((txt, i) => (
            <h1 className="h1" key={i}>
              {txt.title}
            </h1>
          ))
        ) : (
          <h1 className="h1">Our Core Values</h1>
        )}
      </div>

      <div className="Objectives_section container grid">
        {Array.isArray(data) && data.length > 0 && data[0].title === "N" ? (
          <div className="objectives__content grid">
            <div className="Objective_Goal">
              <i className="uill uil-heart objectives__icon"></i>
              <h4 className="Objective__Title">Our Values</h4>
              <h5 className="Objective_description">
                <ul>
                  <li>
                    {" "}
                    <strong className="red">S</strong>ympathy{" "}
                  </li>
                  <li>
                    {" "}
                    <strong className="red">A</strong>ccountability{" "}
                  </li>
                  <li>
                    {" "}
                    <strong className="red">M</strong>orality{" "}
                  </li>
                  <li>
                    {" "}
                    <strong className="red">R</strong>espect
                  </li>
                  <li>
                    {" "}
                    <strong className="red">A</strong>ssistance
                  </li>
                  <li>
                    {" "}
                    <strong className="red">W</strong>ork Responsibly{" "}
                  </li>
                  <li>
                    {" "}
                    <strong className="red">I</strong>ntegrity/honesty
                  </li>
                  <li>
                    {" "}
                    <strong className="red">T</strong>o Share Love
                  </li>
                </ul>
              </h5>
            </div>
          </div>
        ) : data.length > 0 ? (
          data.map((txt, i) => {
            if (txt.id === 1 && txt.content !== "N") {
              return (
                <div className="objectives__content grid" key={i}>
                  <div className="Objective_Goal">
                    <i className="uill uil-heart objectives__icon"></i>
                    <h4 className="Objective__Title">{txt.title}</h4>
                    <h5 className="Objective_description">{txt.content}</h5>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className="objectives__content grid">
            <div className="Objective_Goal">
              <i className="uill uil-heart objectives__icon"></i>
              <h4 className="Objective__Title">Our Values</h4>
              <h5 className="Objective_description">
                <ul>
                  <li>
                    {" "}
                    <strong className="red">S</strong>ympathy{" "}
                  </li>
                  <li>
                    {" "}
                    <strong className="red">A</strong>ccountability{" "}
                  </li>
                  <li>
                    {" "}
                    <strong className="red">M</strong>orality{" "}
                  </li>
                  <li>
                    {" "}
                    <strong className="red">R</strong>espect
                  </li>
                  <li>
                    {" "}
                    <strong className="red">A</strong>ssistance
                  </li>
                  <li>
                    {" "}
                    <strong className="red">W</strong>ork Responsibly{" "}
                  </li>
                  <li>
                    {" "}
                    <strong className="red">I</strong>ntegrity/honesty
                  </li>
                  <li>
                    {" "}
                    <strong className="red">T</strong>o Share Love
                  </li>
                </ul>
              </h5>
            </div>
          </div>
        )}

        {Array.isArray(data) && data.length > 1 && data[1].title === "N" ? (
          <div className="objectives__content grid">
            <div className="Objective_Mission">
              <i className="uill uil-eye objectives__icon"></i>
              <h4 className="Objective__Title">Our Vision</h4>
              <h5 className="Objective_description">
                Every person shall have the right to{" "}
                <strong>‘‘BASIC NEEDS’’</strong>, so that children and elder
                people shall deserve a dignified, healthy, and secured life.
              </h5>
            </div>
          </div>
        ) : data.length > 0 ? (
          data.map((txt, i) => {
            if (txt.id === 2 && txt.content !== "N") {
              return (
                <div className="objectives__content grid" key={i}>
                  <div className="Objective_Mission">
                    <i className="uill uil-eye objectives__icon"></i>
                    <h4 className="Objective__Title">{txt.title}</h4>
                    <h5 className="Objective_description">{txt.content}</h5>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className="objectives__content grid">
            <div className="Objective_Mission">
              <i className="uill uil-eye objectives__icon"></i>
              <h4 className="Objective__Title">Our Vision</h4>
              <h5 className="Objective_description">
                Every person shall have the right to{" "}
                <strong>‘‘BASIC NEEDS’’</strong>, so that children and elder
                people shall deserve a dignified, healthy, and secured life.
              </h5>
            </div>
          </div>
        )}

        {Array.isArray(data) && data.length > 2 && data[2].title === "N" ? (
          <div className="objectives__content objectives__content_last grid ">
            <div className="Objective_Goals">
              <i className="uill uil-rocket objectives__icon"></i>
              <h4 className="Objective__Title">Our Mission</h4>
              <h5 className="Objective_description">
                {" "}
                <strong>i. &#160; </strong>Fostering individuals and
                institutions' participation in philanthropy activity in
                protecting and advancing the worth and rights of children and
                elder people.
              </h5>
              <br />
              <h5 className="Objective_description">
                <strong>ii. &#160; </strong>Facilitate destitute, excluded, and
                vulnerable children and elder people in Ethiopia shall have the
                opportunity to improve their lives and to become productive
                citizens who contribute to a positive change in their
                communities.
              </h5>
            </div>
          </div>
        ) : data.length > 0 ? (
          data.map((txt, i) => {
            if (txt.id === 3 && txt.content !== "N") {
              return (
                <div
                  className="objectives__content objectives__content_last grid "
                  key={i}
                >
                  <div className="Objective_Goals">
                    <i className="uill uil-rocket objectives__icon"></i>
                    <h4 className="Objective__Title">{txt.title}</h4>
                    <h5 className="Objective_description"> {txt.content}</h5>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className="objectives__content objectives__content_last grid ">
            <div className="Objective_Goals">
              <i className="uill uil-rocket objectives__icon"></i>
              <h4 className="Objective__Title">Our Mission</h4>
              <h5 className="Objective_description">
                {" "}
                <strong>i. &#160; </strong>Fostering individuals and
                institutions' participation in philanthropy activity in
                protecting and advancing the worth and rights of children and
                elder people.
              </h5>
              <br />
              <h5 className="Objective_description">
                <strong>ii. &#160; </strong>Facilitate destitute, excluded, and
                vulnerable children and elder people in Ethiopia shall have the
                opportunity to improve their lives and to become productive
                citizens who contribute to a positive change in their
                communities.
              </h5>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Objectives;
