import React, { useState, useEffect } from "react";
import OtherSources from "./OtherSources";
import AboutusgalleryImageSliderComponent from "./Aboutusgallery";
import "./About.css";
import axios from "axios";

const About = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/aboutuswhoWeAre`;
    axios
      .get(apiUrl)
      .then((result) => {
        setdata(
          result.data.map((item) => ({
            id: item.id,
            title: item.title,
            content: item.content,
          }))
        );
      })

      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="About_section container" id="about">
      <div className="About__contents">
        <h1 className="about_title">About Us</h1>
      </div>

      <div className="about_section_one ">
        <OtherSources />
      </div>

      <div className="about_section_two">
        <div className="about_section_Description container">
          <div className="AboutUs">
            {Array.isArray(data) &&
              data.length > 0 &&
              data[0].content === "N" ? (
              <div>
                <h4 className="AboutUs__Title">who we are</h4>
                <h5 className="AboutUs_txt">
                  The Foundation was founded to share what we have with the
                  humanitarian charity work it is currently undertaking, with
                  the principle of our organization: "I will not wait for you to
                  spare me to help those in need." Accordingly, we have been
                  helping more than 75 children and the elderly in various forms
                  over the past seven months since the organization was founded
                  and completed the legal system to start operations. The
                  children and elderly who are being helped by our organization
                  have deep family poverty and abuse, and many of them have
                  disabilities and health problems.
                </h5>
              </div>
            ) : data.length > 0 ? (
              data.map((txt, i) => (
                <div key={i}>
                  <h4 className="AboutUs__Title">{txt.title}</h4>
                  <h5 className="AboutUs_txt">{txt.content}</h5>
                </div>
              ))
            ) : (
              <div>
                <h4 className="AboutUs__Title">who we are</h4>
                <h5 className="AboutUs_txt">
                  The Foundation was founded to share what we have with the
                  humanitarian charity work it is currently undertaking, with
                  the principle of our organization: "I will not wait for you to
                  spare me to help those in need." Accordingly, we have been
                  helping more than 75 children and the elderly in various forms
                  over the past seven months since the organization was founded
                  and completed the legal system to start operations. The
                  children and elderly who are being helped by our organization
                  have deep family poverty and abuse, and many of them have
                  disabilities and health problems.
                </h5>
              </div>
            )}
          </div>

          <div className="about_section_images">
            <AboutusgalleryImageSliderComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
