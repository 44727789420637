import React, { useState, useEffect } from 'react';
import "./MessageStyle.css";

const MessageComponent = ({ type, text }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 30000); // 30 seconds

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setVisible(false);
    };

    const messageStyle = {
        position: 'fixed',
        top: '5px', // Adjust this value to position the message under the URL bar
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '10px 20px',
        borderRadius: '5px',
        color: 'white',
        backgroundColor: type === 'error' ? 'red' : 'green',
        zIndex: '9999',
        display: visible ? 'block' : 'none',
    };

    return (
        <div style={messageStyle} >
            <div className=" messageSection ">
                <span className='messagetxt'>{text}</span>
                <button onClick={handleClose} className='MessageBtn'><div className="error__close">
                    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                        <path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="red"></path>
                    </svg>
                </div>
                </button>
            </div>

        </div>
    );
};

export default MessageComponent;
