import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Gallaries.css";
import galleryImages from "../../Data/GalleryImages";

const GalleryImages = () => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [images, setImages] = useState([]);

  const firstSourceLimit = 8;

  // Extract first 8 records from the first source
  const firstSourceFirstPart = galleryImages.slice(0, firstSourceLimit);

  // Extract remaining records from the first source
  const firstSourceRemaining = galleryImages.slice(firstSourceLimit);

  // Combine images
  const allDBimages = images.map((dbimg) => {
    return {
      img: `${process.env.REACT_APP_API_URL}${dbimg.image}`
    };
  });

  const combinedImages = [
    ...firstSourceFirstPart,
    ...allDBimages,
    ...firstSourceRemaining,
  ];

  // Open modal
  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Previous image
  const prevSlide = () => {
    setSlideNumber(slideNumber === 0 ? combinedImages.length - 1 : slideNumber - 1);
  };

  // Next image
  const nextSlide = () => {
    setSlideNumber((slideNumber + 1) % combinedImages.length);
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/get-Gallaries-picture`;
    axios
      .get(apiUrl)
      .then((result) => {
        setImages(result.data);
      })
      .catch((err) => {
        console.error('Error fetching images:', err);
      });
  }, []);

  return (
    <div className="GalleryImages_container container">
      {openModal && (
        <div className="sliderWrap">
          <i className="uil uil-times-circle btnClose" onClick={handleCloseModal}></i>
          <i className="uil uil-step-backward btnPrev" onClick={prevSlide}></i>
          <i className="uil uil-skip-forward-alt btnNext" onClick={nextSlide}></i>

          <div className="fullScreenImage">
            <img src={combinedImages[slideNumber].img} alt="" />
          </div>
        </div>
      )}
      <div className="gallaries_section">
        <div className="wrapper">
          {/* Display combined images */}
          {combinedImages.map((image, index) => (
            <div
              className="card single"
              key={index}
              onClick={() => handleOpenModal(index)}
            >
              <img className="pics" src={image.img} alt={'img'} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryImages;
