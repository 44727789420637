import React, { useState } from "react";
import MessageComponent from "../message/MessageComponent";

const UploadLandingPagePictures = () => {
  const [message, setMessage] = useState(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const isValidImage = reader.result.startsWith("data:image");
      if (isValidImage) {
        setFile(file);
        setMessage(null); // Reset any previous error message
      } else {
        setFile(null);
        setMessage({ type: "error", text: "Please select a valid image file" });
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Check if file is present
    if (file) {
      formData.append("image", file);

      try {
        // Fetch all records
        const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        const totalRecords = data.length;

        // Check if total records are less than 7
        if (totalRecords < 6) {
          // Upload file if total records are less than 7
          const api = `${process.env.REACT_APP_API_URL}api/upload-LandingPage-picture`;
          await fetch(api, {
            method: "POST",
            body: formData,
          });
          setMessage({ type: "success", text: "File uploaded successfully" });
        } else {
          setMessage({
            type: "error",
            text: "Maximum number of records reached",
          });
        }
      } catch (error) {
        setMessage({ type: "error", text: `Error uploading file: ${error}` });
      }
    } else {
      setMessage({ type: "error", text: `Image is not detected` });
    }
  };

  return (
    <form
      className="Admin-form"
      onSubmit={handleSubmit}
      style={{ height: "fit-content" }}
    >
      <h2 className="tit__1">Create Landing Page Pictures</h2>
      <p className="Expensivemessage ExpensiveIcon">
        <strong className="ExpensiveIcon"> + </strong>Landing Page Image
        Uploader.
      </p>

      <div className="Adminflex">
        <label>
          <input
            className="input"
            type="file"
            name="image"
            onChange={handleFileChange}
          />
        </label>
      </div>

      <button className="bookmarkBtn buttonUp">
        <span className="IconContainer">
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Upload</p>
      </button>
      {message && <MessageComponent type={message.type} text={message.text} />}
    </form>
  );
};

export default UploadLandingPagePictures;
