// this file will contains the top management in our foundation

const Ambassadors = [
  {
    name: 'Genet Nigatu',
    title: ' Ambassador',
    image: '/assets/Teams/good_will.jpg',
    twitter: 'https://twitter.com',
    instagram: 'https://instagram.com',
    linkedin: 'https://linkedin.com',
  },

]
export default Ambassadors;