import React, { useState } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

const UpdateGetInTouchUs = () => {
  const [title, setTitle] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [phone, setPhone] = useState("");
  const [facebook, setFacebook] = useState("");
  const [thought, setThought] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.length >= 50) {
      return setMessage({
        type: "error",
        text: "Content and title must be under 300 and 50 characters",
      });
    }
    if (
      whatsapp.length >= 100 ||
      phone.length >= 100 ||
      facebook.length >= 100 ||
      thought.length >= 100
    ) {
      return setMessage({
        type: "error",
        text: "This field must be under 300 and 50 characters",
      });
    }

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/updategetintouchus`;
      await axios.post(apiUrl, {
        title,
        whatsapp,
        phone,
        facebook,
        thought,
      });
      setMessage({ type: "success", text: "Record updated successfully" });
    } catch (error) {
      console.error("Error updating record:", error);
      setMessage({ type: "error", text: `Failed to update record. ${error}` });
    }
  };

  return (
    <form
      className="Admin-form"
      onSubmit={handleSubmit}
      style={{ height: "fit-content" }}
    >
      <h2 className="tit__1">Update Contact Information</h2>
      <p className="Expensivemessage ExpensiveIcon">
        <strong className="ExpensiveIcon"> + </strong>This is Contact
        Information Content.
      </p>

      <p className="Expensivemessage">
        <strong className="ExpensiveIcon"> + </strong> If you leave these Field
        Empty, <u className="black">the default value will be displayed.</u>
      </p>

      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Title ... 50 characters"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />{" "}
        </label>
      </div>

      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Whatsapp ... 100 characters"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
          />{" "}
        </label>
      </div>

      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Phone ... 100 characters"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />{" "}
        </label>
      </div>

      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Facebook ... 100 characters"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />{" "}
        </label>
      </div>

      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Thought ... 100 characters"
            value={thought}
            onChange={(e) => setThought(e.target.value)}
          />{" "}
        </label>
      </div>

      <button className="bookmarkBtn">
        <span className="IconContainer">
          {" "}
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            {" "}
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>{" "}
          </svg>
        </span>
        <p className="text">Update</p>
      </button>
      {message && <MessageComponent type={message.type} text={message.text} />}
    </form>
  );
};

export default UpdateGetInTouchUs;
