// this file contains all images which will be used in ALL Landing Pages


const LandingPages = [
    {
        id: '1',
        title: 'Home',
        img: process.env.PUBLIC_URL + '/assets/family/samri02.jpg',
    },
    {
        id: '2',
        title: 'Foundation',
        img: process.env.PUBLIC_URL + '/assets/Inauguration/photo_2023-12-29_16-45-55-2.jpg',
    },
    {
        id: '3',
        title: 'About',
        img: process.env.PUBLIC_URL + '/assets/Inauguration/photo_2023-12-29_16-47-29-2.jpg',
    },
    {
        id: '4',
        title: 'Galleries',
        img: process.env.PUBLIC_URL + '/assets/img/image2-2.png',
    },
    {
        id: '5',
        title: 'Service',
        img: process.env.PUBLIC_URL + '/assets/Inauguration/photo_2023-12-29_16-47-33-dark.jpg',
    },
    {
        id: '6',
        title: 'Donate',
        img: process.env.PUBLIC_URL + '/assets/Inauguration/photo_2023-12-29_16-46-26-2.jpg',
    },

];


export default LandingPages