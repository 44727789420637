import React, { useState, useEffect } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

const AddProfessionForm = () => {
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState(null);
  const [categoryCount, setCategoryCount] = useState(0);

  useEffect(() => {
    const fetchCategoryCount = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/allProffesionsdata`;
        const response = await axios.get(apiUrl);
        setCategoryCount(response.data.length);
      } catch (error) {
        setMessage({ type: "error", text: `Error fetching records: ${error}` });
      }
    };

    fetchCategoryCount();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category.length >= 100) {
      return setMessage({
        type: "error",
        text: "Category must be under 100 characters",
      });
    }
    if (categoryCount >= 4) {
      return setMessage({
        type: "error",
        text: "Maximum number of categories reached",
      });
    }

    try {
      const api = `${process.env.REACT_APP_API_URL}api/createAddProfession`;
      await axios.post(api, { category });
      setCategory("");
      setCategoryCount((prevCount) => prevCount + 1);
      setMessage({ type: "success", text: "Category added successfully" });
    } catch (error) {
      setMessage({
        type: "error",
        text: `Error adding category: ${error.message}`,
      });
    }
  };

  return (
    <form
      className="Admin-form"
      style={{ height: "fit-content" }}
      onSubmit={handleSubmit}
    >
      <h2 className="tit__1">Create Team Category</h2>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">Note </strong>{" "}
        <u className="red">You can create only 4 Categorys</u>
      </p>

      <div className="flex">
        <label>
          <input
            className="input"
            type="text"
            placeholder="Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />{" "}
        </label>
      </div>

      <button className="bookmarkBtn">
        <span className="IconContainer">
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Create</p>
      </button>

      {message && <MessageComponent type={message.type} text={message.text} />}
    </form>
  );
};

export default AddProfessionForm;
