import React, { useEffect, useState } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

function ShowYoutube() {
  const [message, setMessage] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/getAboutusyoutubes`;
    axios
      .get(apiUrl)
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.error(err);
        setMessage({
          type: "error",
          text: `Error fetching images: ${err.message}`,
        });
      });
  }, []);

  const handleDelete = async (id) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/deleteAboutusyoutubes/${id}`;
      await axios.delete(apiUrl);
      setImages(images.filter((image) => image.id !== id));
      setMessage({ type: "success", text: "Image deleted successfully" });
    } catch (error) {
      setMessage({
        type: "error",
        text: `Error deleting image: ${error.message}`,
      });
    }
  };
  const sortedImages = images.sort((a, b) => b.id - a.id);

  return (
    <div className="GalleryPictuercontainer">
      <br />
      {sortedImages.map((image) => (
        <div key={image.id} className="GalleryPictuer">
          <img
            src={`${process.env.REACT_APP_API_URL}${image.image}`}
            alt=""
            className="Gallery-Img"
          />
          <button
            className="Gallery-D-button"
            onClick={() => handleDelete(image.id)}
          >
            <span className="Gallery-D-text">Delete</span>
            <span className="Gallery-D-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
              </svg>
            </span>
          </button>
        </div>
      ))}
      {message && <MessageComponent type={message.type} text={message.text} />}
    </div>
  );
}

export default ShowYoutube;
