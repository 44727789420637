// this is the image slider which is presented in the home section beside to who we are


const galleryImagesSlider = [
    {
        title: 'aaaaaaaaaaaaaaa',
        img: process.env.PUBLIC_URL + '/assets/services/image1.png',
    },
    {
        title: 'bbbbbbbbbbbbbb',
        img: process.env.PUBLIC_URL + '/assets/services/image2.png',
    },
    {
        title: 'cccccccccccccc',
        img: process.env.PUBLIC_URL + '/assets/services/image3.jpg',
    },
    {
        title: 'dddddddddddddd',
        img: process.env.PUBLIC_URL + '/assets/services/image4.jpg',
    },


];

export default galleryImagesSlider;
