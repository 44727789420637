import React, { useState } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

const Location = () => {
  const [message, setMessage] = useState(null);
  const [title, setTitle] = useState("");
  const [degree, setDegree] = useState("");
  const [position, setPosition] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.length >= 50 || degree.length >= 50 || position.length >= 300) {
      return setMessage({
        type: "error",
        text: "Title, Degree and Position must be under 50 and 300 characters",
      });
    }
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/updatemaplocationService`;
      await axios.post(apiUrl, { title, degree, position });
      setMessage({ type: "success", text: "Record updated successfully" });
      // console.log(response.data.message);
    } catch (error) {
      console.error("Error updating text content:", error);
      setMessage({
        type: "error",
        text: `An error occurred. Please try again. ${error}`,
      });
    }
  };

  return (
    <form
      className="Admin-form"
      onSubmit={handleSubmit}
      style={{ height: "fit-content" }}
    >
      <h2 className="tit__1">Update Location Content</h2>
      <p className="Expensivemessage ExpensiveIcon">
        <strong className="ExpensiveIcon"> + </strong>This is Loaction Content.
      </p>

      <p className="Expensivemessage">
        <strong className="ExpensiveIcon"> + </strong> If you leave these Field
        Empty, <u className="black">the default value will be displayed.</u>
      </p>

      <div className="flex">
        <label>
          <input
            className="input"
            type="text"
            placeholder="Title ... 50 characters"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
      </div>

      <div className="flex">
        <label>
          <input
            placeholder="Degree ... 50 characters"
            name="text"
            className="input"
            type="text"
            value={degree}
            onChange={(e) => setDegree(e.target.value)}
          ></input>
        </label>
      </div>

      <div className="flex">
        <label>
          <input
            placeholder="Position ... 300 characters"
            name="text"
            className="input"
            type="text"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          ></input>
        </label>
      </div>
      <button className="bookmarkBtn">
        {" "}
        <span className="IconContainer">
          {" "}
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            {" "}
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Submit</p>
      </button>
      {message && <MessageComponent type={message.type} text={message.text} />}
    </form>
  );
};

export default Location;
