import React, { useEffect, useState } from "react";
import axios from "axios";
import "./About.css";
import { Link } from "react-router-dom";
import gallaryYoutubeLink from "../../Data/AboutUsYoutube";

const AboutusYoutubeLink = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/getAboutusyoutubes`;
    axios
      .get(apiUrl)
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.error("Error fetching images:", err);
      });
  }, []);

  const sortedImages = [...images].sort((a, b) => b.id - a.id);

  return (
    <div className="YoutubeVideosContainer container">
      {sortedImages.length > 0 ? (
        <div className="allVideos ">
          <div className="card-grid youtubegallary">
            {sortedImages.map((image, i) => (
              <div className="Youtube_card" key={i}>
                <div className="card-info ytcard-info">
                  <Link className="title" to={image.youtube}>
                    <img
                      className="youtubeImg"
                      src={`${process.env.REACT_APP_API_URL}${image.image}`}
                      alt={`${image.id}-img`}
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="allVideos ">
          <div className="card-grid youtubegallary">
            {gallaryYoutubeLink.map((yt, index) => (
              <div className="Youtube_card" key={index}>
                <div className="card-info ytcard-info">
                  <Link className="title" to={yt.youtube}>
                    <img
                      className="youtubeImg"
                      src={process.env.PUBLIC_URL + yt.image}
                      alt={`${yt.title}-img`}
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutusYoutubeLink;
