import React from 'react';
// import TemsLandingComponent from './TemsLanding';
import UpdateBoardTeamMemberComponent from './UpdateBoardTeamMember';
import Sidebar from '../../global/Sidebar';
import TopBar from '../../global/TopBar';
import Footer from "../../../components/footer/Footer";
import LevelofauthoritytitlesComponent from './Levelofauthoritytitles'
import AddTeamMemberFormComponent from './AddTeamMemberForm'
import AddProfessionForm from './AddProfessionForm';


const AdminTeamsPage = () => {
  return (
    <div className='userStatus'>
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>

        <div className="sidebar" style={{ backgroundColor: "#21232d", height: "100%" }}>
          <Sidebar />
        </div>

        <div className="adminpageS container Admincenter">

          <div className=' D-vertical'>
            {/* <TemsLandingComponent /> */}
            <AddTeamMemberFormComponent />
            <AddProfessionForm />


          </div>

          <div className="D-vertical" >
            <UpdateBoardTeamMemberComponent />
            <LevelofauthoritytitlesComponent />

          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter"><Footer /></div>
    </div>
  )
}

export default AdminTeamsPage