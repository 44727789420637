// Reminder
// The following datas are placed under who we are card in the about us page

// All the youtube links are not from our youtube pages

const gallaryYoutubeLink = [
  // 4   => first four
  {
    title: "Founder & Executive Board chairman",
    image: "/assets/youtube/1.png",
    youtube: "https://www.youtube.com/watch?v=Oslc2H6WcgA",
  },
  {
    title: "Founder & Executive Board chairman",
    image: "/assets/youtube-placeholder.jpeg",
    youtube: "https://www.youtube.com/",
  },
  {
    title: "Founder & Executive Board chairman",
    image: "/assets/youtube-placeholder.jpeg",
    youtube: "https://www.youtube.com/",
  },
  {
    title: "Founder & Executive Board chairman",
    image: "/assets/youtube-placeholder.jpeg",
    youtube: "https://www.youtube.com/",
  },
];

export default gallaryYoutubeLink;
