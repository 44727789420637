import React, { useState } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

function OurcampaignsContent() {
  const [message, setMessage] = useState(null);

  const [image, setImage] = useState(null);
  const [content, setContent] = useState("");

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", image);
    formData.append("content", content);

    if (content.length >= 300) {
      return setMessage({
        type: "error",
        text: "Content must be under 300 characters",
      });
    }

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/postCampaignsContent`;
      await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage({ type: "success", text: "Record updated successfully" });
    } catch (error) {
      setMessage({ type: "error", text: "Failed to upload content" });
    }
  };

  return (
    <form
      className="Admin-form"
      onSubmit={handleSubmit}
      style={{ height: "fit-content", marginTop: "-10%" }}
    >
      <h2 className="tit__1">Who We Are Image Section</h2>
      <p className="Expensivemessage ExpensiveIcon">
        <strong className="ExpensiveIcon"> + </strong>This is Who We Image
        section.
      </p>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon"> + </strong> If you leave this Upload
        Field Empty,{" "}
        <u className="black">the default Image will be displayed.</u>
      </p>
      <div className="flex">
        <label>
          <input
            className="input"
            type="file"
            name="image"
            onChange={handleFileChange}
          />
        </label>
      </div>
      <div className="Admininput-wrapper">
        <textarea
          placeholder="Description ... 300 characters"
          name="text"
          className="Admininput"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        ></textarea>
      </div>
      <button className="bookmarkBtn">
        <span className="IconContainer">
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Upload</p>
      </button>
      {message && <MessageComponent type={message.type} text={message.text} />}
    </form>
  );
}

export default OurcampaignsContent;
