import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Us.css";
import AboutHeader from "../../components/About/AboutHeader";
import About from "../../components/About/About";
import Header from "../../components/header/Header";
import AboutusYoutubeLink from "../../components/About/AboutusYoutubeLink";
import Footer from "../../components/footer/Footer";
import LandingPagesPicture from "../../Data/LandingPages";


function Us() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
        const response = await axios.get(apiUrl);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const AboutUsLandingPicture = LandingPagesPicture.find(page => page.id === '3');


  return (
    <div className="fullpageStyle">
      <div className="">
        {data.length >= 3 && data[2].image !== "N" ? (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={`${process.env.REACT_APP_API_URL}${data[2].image}`}
                alt="AboutUsLanding-page"
              />
            </div>
            <div className="container">
              <AboutHeader />
            </div>
          </div>
        ) : (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={AboutUsLandingPicture.img} alt={AboutUsLandingPicture.title}
              />
            </div>
            <div className="container">
              <AboutHeader />
            </div>
          </div>
        )}

        {/* <div className="FoundationBody"> */}
        <div className="">
          <About />
          <AboutusYoutubeLink />
        </div>

        <div className="footerDIV">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Us;
