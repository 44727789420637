// ForgotPasswordForm.jsx
import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import MessageComponent from "../adminComponents/message/MessageComponent";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Submitting email:", email);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/forgotPass`;
      const response = await axios.post(apiUrl, { email });
      console.log("Response from backend:", response.data);
      setMessage({ type: "success", text: "Email Sent successfully" });
      navigate("/reset-pass");
    } catch (error) {
      setMessage({ type: "error", text: `Email not sent${error}` });
    }
  };

  return (
    <div className="Resetcard">
      <span className="Resetcard__title">Reset User Account</span>
      <p className="Resetcard__content red">
        Please enter your verified email address registered with us to reset
        your account.
      </p>

      <div className="Resetcard__form">
        <input
          className="Resetcard__formInput"
          placeholder="Your Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{
            fontFamily:
              'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
          }}
        />

        <button className="Resetbutton" onClick={handleSubmit}>
          {" "}
          <span className="transition"></span>{" "}
          <span className="gradient"></span>{" "}
          <span className="label">Reset Account</span>
        </button>
        {message && (
          <MessageComponent type={message.type} text={message.text} />
        )}
        <Link to="/login" className="cssbuttons-io-button">
          {" "}
          Go Back
          <div className="icon">
            <svg
              height="24"
              width="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              style={{ transform: "rotate(180deg)" }}
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
