// this file contains all images whuch will be used in Gallery page


const galleryImages = [
    {
        id: '1',
        title: 'Samri-1',
        img: process.env.PUBLIC_URL + '/assets/family/samri02.jpg',
    },
    {
        id: '2',
        title: 'Samri-2',
        img: process.env.PUBLIC_URL + '/assets/family/samri-02.jpg',
    },
    {
        id: '3',
        title: 'Samri-3',
        img: process.env.PUBLIC_URL + '/assets/family/photo_2023-12-23_09-33-39.jpg',
    },
    {
        id: '4',
        title: 'Family-2',
        img: process.env.PUBLIC_URL + '/assets/family/TG-ET-Ge.jpg',
    },
    {
        id: '5',
        title: 'The-Team-1',
        img: process.env.PUBLIC_URL + '/assets/Teams/team-1.jpg',
    },
    {
        id: '6',
        title: 'Family-Team',
        img: process.env.PUBLIC_URL + '/assets/Teams/team-2.jpg',
    },
    {
        id: '7',
        title: 'Family-Team-1',
        img: process.env.PUBLIC_URL + '/assets/services/photo_2023-12-29_16-46-49.jpg',
    },
    {
        id: '8',
        title: 'The-Team-2',
        img: process.env.PUBLIC_URL + '/assets/Teams/team-3.png',
    },
    //12
    {
        id: '9',
        title: 'Dr-wedageneh',
        img: process.env.PUBLIC_URL + '/assets/Inauguration/team-06.png',
    },
    {
        id: '10',
        title: 'Inauguration-pic-1',
        img: process.env.PUBLIC_URL + '/assets/Inauguration/team-05.png',
    },
    {
        id: '11',
        title: 'Inauguration-Signature',
        img: process.env.PUBLIC_URL + '/assets/Inauguration/team-08.png',
    },
    {
        id: '12',
        title: 'Inauguration-pic-2',
        img: process.env.PUBLIC_URL + '/assets/Inauguration/photo_2023-12-29_16-47-08.jpg',
    },
    // 16
    {
        id: '13',
        title: 'Services-1',
        img: process.env.PUBLIC_URL + '/assets/services/image0.png',
    },
    {
        id: '14',
        title: 'Services-2',
        img: process.env.PUBLIC_URL + '/assets/services/team-10.png',
    },
    {
        id: '15',
        title: 'Services-3',
        img: process.env.PUBLIC_URL + '/assets/services/team-11.png',
    },
    {
        id: '16',
        title: 'Services-4',
        img: process.env.PUBLIC_URL + '/assets/services/service3.png',
    },
    // 20
    {
        id: '17',
        title: 'Services-5',
        img: process.env.PUBLIC_URL + '/assets/services/service2.jpg',
    },
    {
        id: '18',
        title: 'Services-6',
        img: process.env.PUBLIC_URL + '/assets/services/team-14.jpg',
    },
    {
        id: '19',
        title: 'Services-7',
        img: process.env.PUBLIC_URL + '/assets/services/team-15.jpg',
    },
    {
        id: '20',
        title: 'Services-8',
        img: process.env.PUBLIC_URL + '/assets/services/image2.png',
    },
];

export default galleryImages;
