import React, { useState } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

const Whoweare = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (content.length >= 300) {
      return setMessage({
        type: "error",
        text: "Content must be 300 characters or less",
      });
    }

    if (title.length >= 50) {
      return setMessage({
        type: "error",
        text: "Title must be 50 characters or less",
      });
    }
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/updateaboutuswhoWeAre`;
      await axios.post(apiUrl, { title, content });
      setMessage({
        type: "success",
        text: "Who We Are section was updated successfully",
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: `Error updating Who We Are section: ${error}`,
      });
    }
  };

  return (
    <form
      className="Admin-form"
      onSubmit={handleSubmit}
      style={{ height: "fit-content" }}
    >
      <h2 className="tit__1">Who We Are Section Content</h2>
      <p className="Expensivemessage ExpensiveIcon">
        <strong className="ExpensiveIcon"> + </strong>This is Who We are
        Content, placed next to the image slider
      </p>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon"> + </strong> If you leave this title
        and text area empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>

      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Title ... 50 characters"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />{" "}
        </label>
      </div>

      <div className="Admininput-wrapper">
        <textarea
          placeholder="Description...300 characters"
          name="text"
          className="Admininput"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        ></textarea>
      </div>

      <button className="bookmarkBtn">
        {" "}
        <span className="IconContainer">
          {" "}
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            {" "}
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>{" "}
          </svg>{" "}
        </span>
        <p className="text">Submit</p>
      </button>
      {message && <MessageComponent type={message.type} text={message.text} />}
    </form>
  );
};

export default Whoweare;
