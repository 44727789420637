import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Alluser.css";
import MessageComponent from "../message/MessageComponent";

const AllUsers = () => {
  const [message, setMessage] = useState(null);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/users`;
        const response = await axios.get(apiUrl);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
        setMessage({ type: "error", text: `Error fetching users: ${error}` });
      }
    };
    fetchData();
  }, []);

  const handleDeleteUser = async (userId) => {
    try {
      const api = `${process.env.REACT_APP_API_URL}api/rmv-user-acc/${userId}`;
      const response = await fetch(api, {
        method: "DELETE",
      });
      await response.json();
      // Remove the deleted user from the users state
      setUsers(users.filter((user) => user.id !== userId));
      setMessage({ type: "success", text: "User Deleted Successfully" });
      // window.location.reload();
    } catch (error) {
      setMessage({ type: "error", text: `Error deleting user: ${error}` });
    }
  };

  return (
    <div className="table_container">
      <div className="tbl__container">
        <p className="tbl__container-admin">All Users</p>
        <table className="Admin-tbl">
          <thead>
            <tr>
              <th>User Name</th>
              <th>First Name</th>
              <th>Email</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, i) => (
              <tr key={i}>
                <td data-table="user.username">{user.username}</td>
                <td data-table="user.firstname">{user.firstname}</td>
                <td data-table="user.email">{user.email}</td>
                <td data-table="user.id">
                  <button
                    className="bin-button"
                    onClick={() => handleDeleteUser(user.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 39 7"
                      className="bin-top"
                    >
                      <line
                        strokeWidth="4"
                        stroke="white"
                        y2="5"
                        x2="39"
                        y1="5"
                      ></line>
                      <line
                        strokeWidth="3"
                        stroke="white"
                        y2="1.5"
                        x2="26.0357"
                        y1="1.5"
                        x1="12"
                      ></line>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 33 39"
                      className="bin-bottom"
                    >
                      <mask fill="white" id="path-1-inside-1_8_19">
                        <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
                      </mask>
                      <path
                        mask="url(#path-1-inside-1_8_19)"
                        fill="white"
                        d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                      ></path>
                      <path
                        strokeWidth="4"
                        stroke="white"
                        d="M12 6L12 29"
                      ></path>
                      <path strokeWidth="4" stroke="white" d="M21 6V29"></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 89 80"
                      className="garbage"
                    >
                      <path
                        fill="white"
                        d="M20.5 10.5L37.5 15.5L42.5 11.5L51.5 12.5L68.75 0L72 11.5L79.5 12.5H88.5L87 22L68.75 31.5L75.5066 25L86 26L87 35.5L77.5 48L70.5 49.5L80 50L77.5 71.5L63.5 58.5L53.5 68.5L65.5 70.5L45.5 73L35.5 79.5L28 67L16 63L12 51.5L0 48L16 25L22.5 17L20.5 10.5Z"
                      ></path>
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          {message && (
            <MessageComponent type={message.type} text={message.text} />
          )}
        </table>
      </div>
    </div>
  );
};

export default AllUsers;
