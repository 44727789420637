import React from 'react'
import AllusersComponent from './Allusers'
import CreatuserComponent from './Creatuser'
import Sidebar from '../../global/Sidebar'
import TopBar from '../../global/TopBar'
import Footer from "../../../components/footer/Footer";



const Users = () => {
  return (
    <div className='userStatus'>
      <div className="Adminheader">
        <TopBar/>
      </div>
      <div className="sidebar_and_Page" style={{height:"fit-content"}}>
        <div className="sidebar" style={{backgroundColor:"#21232d", height:"100%"}}>
          <Sidebar/>
        </div>

        <div className="adminpageS container Admincenter">
          <CreatuserComponent/>
          <AllusersComponent/>
        </div>
      </div>
      <div className="footerDIV AdminFooter"><Footer/></div>
    </div>
  )
}

export default Users