import React from "react";
import HomePictureTxtOneComponent from "./HomePictureTxtOne";
import HomePictureTxtTwoComponent from "./HomePictureTxtTwo";
import HomepageAboutSM from "./HomepageAboutSM";
import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";
import Createhomepicturelandingtxt from "./Createhomepicturelandingtxt";

const AdminHomepage = () => {
  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="adminpageS container Admincenter">
          <div className="D-vertical">
            <Createhomepicturelandingtxt />
            <HomePictureTxtOneComponent />
          </div>
          <div className="D-vertical">
            <HomePictureTxtTwoComponent />
            <HomepageAboutSM />
          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminHomepage;
