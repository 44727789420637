import React, { useState, useEffect } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

function Levelofauthoritytitles() {
  const [recordId, setRecordId] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState(null);
  const [recordIds, setRecordIds] = useState([]);

  useEffect(() => {
    fetchRecordIds();
  }, []);

  const fetchRecordIds = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/allProffesionsdata`;
      const response = await axios.get(apiUrl);
      setRecordIds(response.data);
    } catch (error) {
      console.error("Error fetching record IDs:", error);
    }
  };

  const updateRecord = async () => {
    try {
      if (category.length >= 100) {
        return setMessage({
          type: "error",
          text: "Category must be under 100 characters",
        });
      }
      const api = `${process.env.REACT_APP_API_URL}api/updateallProffesionsdata/${recordId}`;
      await axios.post(api, {
        category,
      });

      setMessage({ type: "success", text: "Category updated successfully" });
    } catch (error) {
      console.error("Error updating record:", error);
      setMessage({ type: "error", text: `Failed to update Category ${error}` });
    }
  };

  return (
    <form className="Admin-form" style={{ height: "fit-content" }}>
      <h2 className="tit__1">Update Team Category</h2>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">+ </strong>{" "}
        <u className="red">Team Hierarchy Updater</u>
      </p>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon"> + </strong> If you leave this title
        Field Empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>
      <div className="flex">
        <label>
          <select
            className="input"
            value={recordId}
            onChange={(e) => setRecordId(e.target.value)}
          >
            <option value="">Select Category ID</option>
            {recordIds.map((record) => (
              record.category === "N" ? (
                <option key={record.id} value={record.id}>
                  {record.id}
                </option>
              ) : (
                <option key={record.id} value={record.id}>
                  {record.category}
                </option>
              )
            ))}
          </select>

        </label>
      </div>

      <div className="flex">
        <label>
          <input
            className="input"
            type="text"
            placeholder="Category "
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />{" "}
        </label>
      </div>

      <button
        className="bookmarkBtn"
        onClick={updateRecord}
        disabled={!recordId}
      >
        <span className="IconContainer">
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Update</p>
      </button>

      {message && <MessageComponent type={message.type} text={message.text} />}
    </form>
  );
}

export default Levelofauthoritytitles;
