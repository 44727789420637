import React from 'react';
// import ServiceLandingComponent from './ServiceLanding';
// import OurcampaignstitleComponent from './Ourcampaignstitle';
// import OurcampaignsContentComponent from './OurcampaignsContent';
// import UpdateContentContentComponent from './UpdateContent';
// import SliderComponent from './Slider'
import UploadSlideImageComponent from './UploadSlideImage'
import DeleteSlideImageComponent from './DeleteSlideImage'
import UpdateSliderimageComponent from './UpdateSliderimage'


// import CampaignsContent from './CampaignsContent'
import Sidebar from '../../global/Sidebar'
import TopBar from '../../global/TopBar'
import Footer from "../../../components/footer/Footer";


const AdminSlider = () => {
  return (
    <div className='userStatus'>
      <div className="Adminheader">
        <TopBar />
      </div>

      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div className="sidebar" style={{ backgroundColor: "#21232d", height: "100%" }}>
          <Sidebar />
        </div>

        <div className="adminpageS container Admincenter" >

          <div className="D-vertical" >
            <UploadSlideImageComponent />
            <DeleteSlideImageComponent />
          </div>

          <div className="D-vertical">
            <UpdateSliderimageComponent />
          </div>


        </div >
      </div>

      <div className="footerDIV AdminFooter"><Footer /></div>
    </div>
  )
}


export default AdminSlider