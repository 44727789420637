import React from "react";
import AdminAboutUsPageComponent from "./AdminAboutUsPage";
import WhoweareComponent from "./Whoweare";
import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";
import AboutusgalleryImageSlider from "./AboutusgalleryUploadform";
import AboutusgalleryComponent from "./Aboutusgallery";
import AboutusyoutubeComponent from "./Aboutusyoutube";
import ShowYoutube from "./ShowYoutube";

const AdminAboutPage = () => {
  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="adminpageS container Admincenter">
          <AdminAboutUsPageComponent />
          <WhoweareComponent />

          <div className="D-vertical">
            <AboutusyoutubeComponent />

            <div className="gallaryComponent">
              <div className="gallaryComponent_title">
                <h3>Youtube links</h3>
              </div>
              <div className="toleft">
                <ShowYoutube />
              </div>
            </div>
          </div>

          <div className="D-vertical">
            <AboutusgalleryImageSlider />

            <div className="gallaryComponent">
              <div className="gallaryComponent_title">
                <h3>About Us Images (Slider)</h3>
              </div>
              <div className="toleft">
                <AboutusgalleryComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminAboutPage;
