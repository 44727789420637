import React, { useState } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

const HomePictureTxtOne = () => {
  const [message, setMessage] = useState(null);

  const [content, setContent] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (content.length >= 1000) {
      setMessage({
        type: "error",
        text: "Content must be 1000 characters or less",
      });
      return;
    }
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/updatehomepicturetxt/1`;
      await axios.put(apiUrl, { content: content });
      setMessage({ type: "success", text: "Record updated successfully" });
    } catch (error) {
      console.error("Error updating record:", error);
      setMessage({
        type: "error",
        text: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <form className="Admin-form" onSubmit={handleSubmit}>
      <h2 className="tit__1">Update Paragraph one</h2>
      <p className="Expensivemessage ExpensiveIcon">
        <strong className="ExpensiveIcon"> + </strong>This is Homepage First
        Text.
      </p>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon"> + </strong> If you leave this text
        area empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>

      <br />

      <div className="flex">
        <div className="Admininput-wrapper">
          <textarea
            placeholder="First Paragraph"
            name="text"
            className="Admininput"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
        </div>
      </div>

      <button className="bookmarkBtn">
        <span className="IconContainer">
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Submit</p>
      </button>
      {message && <MessageComponent type={message.type} text={message.text} />}
    </form>
  );
};

export default HomePictureTxtOne;
