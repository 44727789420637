import React from 'react'
import { NavLink } from "react-router-dom";

import { useAuth } from '../global/Auth/AuthProvider';

const Sidebar = () => {

  const { user } = useAuth();

  return (
    <div className='adminSidebar'>
      <aside id="adminsidebar">
        <div className="adminsidebar-title">
          <div className="adminsidebar-brand">
            <br />
            <h3 className="PGname">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Welcome&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
            <br />
            <h3>{user}</h3>
            <br />
          </div>
        </div>
        <ul className="sidebar-list">
          <li className="sidebar-list-item">
            <NavLink to="/AdminPage">
              <span ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className=" AdminIconSidebarlucide lucide-airplay"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1" /><path d="m12 15 5 6H7Z" /></svg></span>&nbsp;&nbsp;<span>Landing Pages</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <NavLink to="/Users-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-user-round-check"><path d="M2 21a8 8 0 0 1 13.292-6" /><circle cx="10" cy="8" r="5" /><path d="m16 19 2 2 4-4" /></svg></span>&nbsp;&nbsp;<span>Users</span>
            </NavLink>
          </li>

          <li className="sidebar-list-item">
            <NavLink to="/adminHomepage-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-book-a"><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" /><path d="m8 13 4-7 4 7" /><path d="M9.1 11h5.7" /></svg></span>&nbsp;&nbsp;<span>Homepage</span>
            </NavLink>

          </li>

          <li className="sidebar-list-item">
            <NavLink to="/AdminFoundationPage-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-landmark"><line x1="3" x2="21" y1="22" y2="22" /><line x1="6" x2="6" y1="18" y2="11" /><line x1="10" x2="10" y1="18" y2="11" /><line x1="14" x2="14" y1="18" y2="11" /><line x1="18" x2="18" y1="18" y2="11" /><polygon points="12 2 20 7 4 7" /></svg></span>&nbsp;&nbsp;<span>Foundation</span>
            </NavLink>
          </li>

          <li className="sidebar-list-item">
            <NavLink to="/AdminAboutPage-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-shield-question"><path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" /><path d="M9.1 9a3 3 0 0 1 5.82 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg></span>&nbsp;&nbsp;<span>About Us</span>
            </NavLink>
          </li>

          <li className="sidebar-list-item">
            <NavLink to="/AdminGallaries-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-images"><path d="M18 22H4a2 2 0 0 1-2-2V6" /><path d="m22 13-1.296-1.296a2.41 2.41 0 0 0-3.408 0L11 18" /><circle cx="12" cy="8" r="2" /><rect width="16" height="16" x="6" y="2" rx="2" /></svg></span>&nbsp;&nbsp;<span>Gallaries</span>
            </NavLink>
          </li>

          <li className="sidebar-list-item">
            <NavLink to="/AdminservicePage-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-hand-platter"><path d="M12 3V2" /><path d="M5 10a7.1 7.1 0 0 1 14 0" /><path d="M4 10h16" /><path d="M2 14h12a2 2 0 1 1 0 4h-2" /><path d="m15.4 17.4 3.2-2.8a2 2 0 0 1 2.8 2.9l-3.6 3.3c-.7.8-1.7 1.2-2.8 1.2h-4c-1.1 0-2.1-.4-2.8-1.2L5 18" /><path d="M5 14v7H2" /></svg></span>&nbsp;&nbsp;<span>Services</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <NavLink to="/AdminDonatePage-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-hand-coins"><path d="M11 15h2a2 2 0 1 0 0-4h-3c-.6 0-1.1.2-1.4.6L3 17" /><path d="m7 21 1.6-1.4c.3-.4.8-.6 1.4-.6h4c1.1 0 2.1-.4 2.8-1.2l4.6-4.4a2 2 0 0 0-2.75-2.91l-4.2 3.9" /><path d="m2 16 6 6" /><circle cx="16" cy="9" r="2.9" /><circle cx="6" cy="5" r="3" /></svg></span>&nbsp;&nbsp;<span>Donation</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <NavLink to="/AdminContactPage-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-contact-round"><path d="M16 18a4 4 0 0 0-8 0" /><circle cx="12" cy="11" r="3" /><rect width="18" height="18" x="3" y="4" rx="2" /><line x1="8" x2="8" y1="2" y2="4" /><line x1="16" x2="16" y1="2" y2="4" /></svg></span>&nbsp;&nbsp;<span>Contacts</span>

            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <NavLink to="/AdminTeamsPage-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-users"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" /><circle cx="9" cy="7" r="4" /><path d="M22 21v-2a4 4 0 0 0-3-3.87" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg></span>&nbsp;&nbsp;<span>Team</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <NavLink to="/AdminSlider-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-handshake"><path d="m11 17 2 2a1 1 0 1 0 3-3" /><path d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4" /><path d="m21 3 1 11h-2" /><path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3" /><path d="M3 4h8" /></svg></span>&nbsp;&nbsp;<span>Our Partners</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <NavLink to="/AdminFooterpage-Management">
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="AdminIconSidebar lucide lucide-align-vertical-justify-end"><rect width="14" height="6" x="5" y="12" rx="2" /><rect width="10" height="6" x="7" y="2" rx="2" /><path d="M2 22h20" /></svg></span>&nbsp;&nbsp;<span>Footer</span>
            </NavLink>
          </li>
        </ul>
      </aside>
    </div>
  )
}

export default Sidebar