import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Gallaries.css";
import GallariesHome from "../../components/GallariesHome/GallariesHome";
import GalleryImages from "../../components/GallariesHome/GalleryImages";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import LandingPagesPicture from "../../Data/LandingPages";


const Gallaries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
        const response = await axios.get(apiUrl);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const GalleriesLandingPicture = LandingPagesPicture.find(page => page.id === '4');


  return (
    <div className="fullpageStyle">
      <div className="">
        {data.length >= 4 && data[3].image !== "N" ? (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={`${process.env.REACT_APP_API_URL}${data[3].image}`}
                alt="GalleriesLanding-page"
              />
            </div>
            <div className="container">
              <GallariesHome />
            </div>
          </div>
        ) : (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={GalleriesLandingPicture.img} alt={GalleriesLandingPicture.title}
              />
            </div>
            <div className="container">
              <GallariesHome />
            </div>

          </div>
        )}
      </div>
      <div className="FoundationBody">
        <GalleryImages />
      </div>

      <div className="footerDIV">
        <Footer />
      </div>
    </div>
  );
};

export default Gallaries;
