import React from 'react'
import "./founders.css"
function Founders() {
  return (

      <div className='founder_sescription_Two '>
        <div className="founder__description ">
          <h3 className='valuesTitle'><strong>OUR VALUES</strong></h3>
          <div className="statement">
            <ul className='EngVersion'>
              <li> <strong>S</strong>ympathy </li>
              <li> <strong>A</strong>ccountability </li>
              <li> <strong>M</strong>orality </li>
              <li> <strong>R</strong>espect </li>
              <li> <strong>A</strong>ssistance </li>
              <li> <strong>W</strong>ork</li>
              <li> <strong>I</strong>ntegrity/honesty </li>
              <li> <strong>T</strong>o Share Love </li>
            </ul>

            <ul className='AmhVersion'>
              <li> <p className='valuesAmhric'> በርህራሄ ማገልገል </p></li>
              <li> <p className='valuesAmhric'> ተጠያቂነት </p></li>
              <li> <p className='valuesAmhric'> ሞራላዊ መሆን </p></li>
              <li> <p className='valuesAmhric'> በክብር ማገልገል </p></li>
              <li> <p className='valuesAmhric'> ድጋፍና ትብብር ማድረግ </p></li>
              <li> <p className='valuesAmhric'> በኃላፊነት መስራት </p></li>
              <li> <p className='valuesAmhric'> በቅንነት ማገልገል </p></li>
              <li> <p className='valuesAmhric'> ፍቅር መስጠት </p></li>
            </ul>
          </div>
        </div>
      </div>
  )
}

export default Founders