import React, { useState } from "react";
import axios from "axios";
import zxcvbn from "zxcvbn"; // Library for password strength estimation npm install zxcvbn
import { Link } from "react-router-dom";

const ResetPasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Check password length
      if (password.length < 6) {
        setError("Password must be at least 6 characters long");
        return;
      }

      // Check password strength
      const passwordScore = zxcvbn(password).score;
      if (passwordScore < 3) {
        setError("Please use a stronger password");
        return;
      }

      // Check if passwords match
      if (password !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_API_URL}api/resetPassword`;

      const response = await axios.post(apiUrl, {
        password,
        confirmPassword,
        otp,
      });

      setSuccessMessage(response.data.message);
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const score = zxcvbn(e.target.value).score;
    setPasswordStrength(score);
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
      case 1:
        return "red";
      case 2:
        return "yellow";
      case 3:
      case 4:
        return "green";
      default:
        return "";
    }
  };

  return (
    <div className="Resetcard">
      <span className="Resetcard__title">Password Reset</span>
      <p className="Resetcard__content container">
        <strong>+</strong> For your security, we recommend using a strong
        password with a mix of
      </p>
      <p className="Resetcard__content red">
        <strong>-</strong> uppercase letters, lowercase letters,
      </p>
      <p className="Resetcard__content red">
        <strong>-</strong> numbers, and special characters
      </p>
      <p className="Resetcard__content red">
        <strong>-</strong>(e.g., Aa.12#@)
      </p>
      {error && <p>Error: {error}</p>}
      {successMessage && <p>{successMessage}</p>}

      <form onSubmit={handleSubmit} className="Resetcard__form">
        <input
          className="Resetcard__formInput"
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          required
          placeholder="OTP"
          style={{
            fontFamily:
              'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
          }}
        />

        <input
          className="Resetcard__formInput"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          required
          placeholder="New Password"
          style={{
            backgroundImage: `linear-gradient(to left, ${getPasswordStrengthColor()} -50%, transparent 60%)`,
            fontFamily:
              'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
          }}
        />

        <input
          className="Resetcard__formInput"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          placeholder="Confirm Password"
          style={{
            backgroundImage: `linear-gradient(to left, ${getPasswordStrengthColor()} -50%, transparent 60%)`,
            fontFamily:
              'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
          }}
        />

        <button type="submit" className="Resetbutton">
          {" "}
          <span className="transition"></span>{" "}
          <span className="gradient"></span>{" "}
          <span className="label">Reset</span>
        </button>

        <Link to="/login" className="cssbuttons-io-button">
          {" "}
          Login
          <div className="icon">
            <svg
              height="24"
              width="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              style={{ transform: "rotate(180deg)" }}
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Link>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
