import React, { useEffect, useState } from "react";
import axios from "axios";
import "./About.css";
import { Link } from "react-router-dom";
import majoryoutubeLink from "../../Data/MajoryoutubeLink";

const OtherSources = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/getAboutusyoutubes`;
    axios
      .get(apiUrl)
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.error("Error fetching images:", err);
      });
  }, []);

  const sortedImages = [...images].sort((a, b) => b.id - a.id);

  return (
    <div className="youtubeVideos">
      {sortedImages.length > 0 ? (
        <div className="card-grid gap">
          {sortedImages.splice(0, 4).map((image, i) => (
            <div className="Youtube_card" key={i}>
              <div className="card-info">
                <Link className="title" to={image.youtube}>
                  <img
                    className="youtubeImg"
                    src={`${process.env.REACT_APP_API_URL}${image.image}`}
                    alt={`${image.id}-img`}
                  />
                </Link>
              </div>
            </div>
          ))}
          <Link to="/galleries">
            <button className="button Youtube_cardBtn">
              <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
                <path
                  clipRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </Link>
        </div>
      ) : (
        <div className="card-grid gap">
          {majoryoutubeLink.map((yt, index) => (
            <div className="Youtube_card" key={index}>
              <div className="card-info">
                <Link className="title" to={yt.youtube}>
                  <img
                    className="youtubeImg"
                    src={process.env.PUBLIC_URL + yt.image}
                    alt={`${yt.title}-img`}
                  />
                </Link>
              </div>
            </div>
          ))}

          <Link to="/galleries">
            <button className="button Youtube_cardBtn">
              <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
                <path
                  clipRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default OtherSources;
