import React, { useState, useEffect } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

const UpdateBoardTeams = () => {
  const [ids, setIds] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [name, setName] = useState("");
  const [persontitle, setPersonTitle] = useState("");
  const [facebook, setFacebook] = useState("");
  const [x, setX] = useState("");
  const [linkedln, setlinkedln] = useState("");
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchIds();
  }, []);

  const fetchIds = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/allourboardteamsdata`;
      const response = await axios.get(apiUrl);
      setIds(response.data);
    } catch (error) {
      setMessage({ type: "error", text: `Failed to fetch records. ${error}` });
    }
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name.length >= 100 || persontitle.length >= 100) {
      return setMessage({
        type: "error",
        text: "name and Person Title must be under 200 characters",
      });
    }
    if (facebook.length >= 100 || x.length >= 100 || linkedln.length >= 100) {
      return setMessage({
        type: "error",
        text: "Team contacts must be under 100 characters",
      });
    }
    try {
      const formData = new FormData();
      formData.append("name", name || "");
      formData.append("persontitle", persontitle || "");
      formData.append("facebook", facebook || "");
      formData.append("x", x || "");
      formData.append("linkedln", linkedln || "");
      formData.append("image", image || null);

      if (name === "" || persontitle === "" || image === null) {
        setMessage({ type: "error", text: `Empty User Data`, });
      } else {
        const api = `${process.env.REACT_APP_API_URL}api/updateallourboardteamsdata/${selectedId}`;
        await axios.post(api, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setMessage({ type: "success", text: "User Record updated successfully" });
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: `Failed to update User Record. ${error}`,
      });
    }
  };

  return (
    <div
      className="Admin-form "
      style={{ height: "fit-content", marginTop: "-15%" }}
    >
      <h2 className="tit__1">Update Team Address</h2>
      <p className="Expensivemessage ExpensiveIcon">
        <strong className="ExpensiveIcon"> + </strong>This is Team Contact
        Inforamtion.
      </p>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon"> + </strong> If you leave this title
        Field Empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>

      <br />

      <form onSubmit={handleSubmit}>
        <div className="flex">
          {/* <label htmlFor="id"> */}
          <label>
            <select
              className="input"
              value={selectedId}
              onChange={(e) => setSelectedId(e.target.value)}
            >
              <option value="">Select Team Record</option>
              {ids.map((record) => (
                record.name === "N" ? (
                  <option key={record.id} value={record.id}>
                    {record.id}
                  </option>
                ) : (
                  <option key={record.id} value={record.id}>
                    {record.name}
                  </option>
                )
              ))}
            </select>
          </label>
        </div>
        <br />

        <div className="name_and_title">
          <div className="flex">
            <label>
              <input
                className="input"
                type="text"
                placeholder="Name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
              />
            </label>
          </div>

          <div className="flex">
            <label>
              <input
                className="input"
                type="text"
                placeholder="Title"
                id="persontitle"
                value={persontitle}
                onChange={(e) => setPersonTitle(e.target.value)}
                autoComplete="off"
              />
            </label>
          </div>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="Facebook id"
              id="facebook"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
              autoComplete="off"
            />
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="X id"
              id="x"
              value={x}
              onChange={(e) => setX(e.target.value)}
              autoComplete="off"
            />
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="linkedln id"
              id="linkedln"
              value={linkedln}
              onChange={(e) => setlinkedln(e.target.value)}
              autoComplete="off"
            />
          </label>
        </div>
        <br />

        <div className="flex">
          {" "}
          <label>
            <input
              className="input"
              type="file"
              name="image"
              onChange={handleFileChange}
            />
          </label>
        </div>
        <br />

        <div style={{ display: "grid", justifySelf: "end" }}>
          <button className="bookmarkBtn buttonUp">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Upload</p>
          </button>
        </div>
      </form>

      {message && <MessageComponent type={message.type} text={message.text} />}
    </div>
  );
};

export default UpdateBoardTeams;
