import React from 'react'

const AboutFoundation = () => {
  return (
    <div className="foundation_legal_entity container grid" >

      <div className="englishversion">
        <h2 className='person_the_name'>The foundation has got legal entity from the Federal Democratic Republic of Ethiopia Authority for Civil Society Organizations with certificate no 6345. Currently, the foundation started its operation after signing a tri party project implementation agreement with Addis Ababa city Finance bureau & Addis Ababa city administration women, children & Social affairs bureau in two sub cities of Gulele & Bole. From Gulele sub city Woreda 1 & Woreda 3 from Bole sub city Woreda 12 was chosen for our project implementation.</h2>
      </div>

      <div className="entity-line"></div>

      <div className="amhricversion">
        <h2 className="person_the_name">ድርጅታችን ሳምራዊት ፋዉንዴሽን ከኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ የሲቪል ማህበረሰብ ድርጅቶች ባለስልጣን በሰርተፊኬት ቁጥር 6345 የተመዘገበ ህጋዊ እውቅና ያለው የበጎ አድራጎት ተቋም ነው፡፡ ከዚህ በተጨማሪ ለሚሰራቸው የበጎ አድራጎት ሥራ ከአዲስ አበባ ከተማ አስተዳደር ሴቶች ህጻናትና ማህበራዊ ጉዳይ ቢሮ እና ከአዲስ አበባ ከተማ አስተዳደር ፋይናንስ ቢሮ ጋር የሶስትዮሽ የፕሮጀክት ትግበራ ስምምነትን ተፈራርሞ በከተማችን በሁለት ክፍለ ከተሞች ማለትም በቦሌ እና በጉለሌ ክፍለ ከተማ በሶስት ወረዳዎች ማለትም በጉለሌ ክፍለ ከተማ ወረዳ 1 እና ወረዳ 3 እንዲሁም በቦሌ ክፍለ ከተማ ወረዳ 12 ላይ የሰብዓዊ ድጋፍ አገልግሎቱን እየሰጠ ይገኛል፡፡</h2>
      </div>
    </div>
  )
}

export default AboutFoundation