import React, { useState } from "react";
import MessageComponent from "../message/MessageComponent";

const Aboutusyoutube = () => {
  const [message, setMessage] = useState(null);
  const [file, setFile] = useState(null);

  const [youtube, setyoutube] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const isValidImage = reader.result.startsWith("data:image");
      if (isValidImage) {
        setFile(file);
        setMessage(null); // Reset any previous error message
      } else {
        setFile(null);
        setMessage({ type: "error", text: "Please select a valid image file" });
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (youtube.length >= 300) {
      return setMessage({
        type: "error",
        text: "Link and image name must be under 300 characters",
      });
    }

    // Check if file is present
    if (file) {
      formData.append("image", file);
      formData.append("youtube", youtube); // Append the YouTube link to the form data

      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/uploadAboutusyoutubes`;
        await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });
        setMessage({ type: "success", text: "File uploaded successfully" });
      } catch (error) {
        setMessage({
          type: "error",
          text: `Error uploading file: ${error.message}`,
        });
      }
    } else {
      setMessage({ type: "error", text: "Please select an image" });
    }
  };

  return (
    <form
      className="Admin-form"
      onSubmit={handleSubmit}
      style={{ height: "fit-content" }}
    >
      <h2 className="tit__1">About Us Youtube links</h2>
      <p className="Expensivemessage ExpensiveIcon">
        <strong className="ExpensiveIcon"> + </strong>All youtube videos
      </p>
      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Youtube link"
            value={youtube}
            onChange={(e) => setyoutube(e.target.value)}
          />{" "}
        </label>
      </div>

      <div className="Adminflex">
        <label>
          <input
            className="input"
            type="file"
            name="image"
            onChange={handleFileChange}
          />
        </label>
      </div>

      <button className="bookmarkBtn buttonUp">
        <span className="IconContainer">
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Upload</p>
      </button>
      {message && <MessageComponent type={message.type} text={message.text} />}
    </form>
  );
};
export default Aboutusyoutube;
