


const majoryoutubeLink = [
    {
        title: 'Founder & Executive Board chairman',
        image: '/assets/youtube/1.png',
        youtube: 'https://www.youtube.com/watch?v=Oslc2H6WcgA',
    },
    {
        title: 'Founder & Executive Board chairman',
        image: '/assets/youtube/5.png',
        youtube: 'https://www.youtube.com/watch?v=NvQhPyxpvs4',
    },
    {
        title: 'Founder & Executive Board chairman',
        image: '/assets/youtube/3.png',
        youtube: 'https://www.youtube.com/watch?v=TwvurdBtuX4',
    },
    {
        title: 'Founder & Executive Board chairman',
        image: '/assets/youtube/4.png',
        youtube: 'https://www.youtube.com/watch?v=NoLyOAptMTM',
    },
];


export default majoryoutubeLink;