import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../global/Auth/AuthProvider";

import "./Login.css";

function Login() {
  const { loginAction } = useAuth();

  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    loginAction(formData, setError);
  };

  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/users/count`;
        // const response = await fetch('https://samrawitfoundation.org.et/users/count');
        const response = await fetch(apiUrl);
        if (response.status === 200) {
          const data = await response.json();
          setTotalUsers(data.totalUsers);
        } else {
          setTotalUsers(3); // Set totalUsers to a value greater than 2
        }
      } catch (error) {
        console.error("Error fetching total users:", error);
      }
    };
    fetchTotalUsers();
  }, []);

  return (
    <div className="container LoginContainer">
      <div className="Logincard">
        <img
          src={process.env.PUBLIC_URL + "/assets/logo/sam-logo-12.png"}
          className="LoginLogo"
          alt="Samrawit Logo"
        />

        {error && <div className="error-message">{error}</div>}

        <h1 className="login">Log in</h1>
        <form onSubmit={handleSubmitEvent} className="grid container loginForm">
          <div className="LogininputBox">
            <input
              type="text"
              name="username"
              required="required"
              autoComplete="off"
              onChange={handleInput}
            />
            <span className="Loginuser">Username</span>
          </div>
          <div className="LogininputBox">
            <input
              type="password"
              name="password"
              required="required"
              autoComplete="off"
              aria-describedby="user-password"
              aria-invalid="false"
              onChange={handleInput}
            />
            <span>Password</span>
          </div>

          <button type="submit" className="Loginenter center">
            Enter
          </button>
          <div className="creatandReset">
            {totalUsers !== null && totalUsers <= 2 && (
              <div className="creatandReset">
                <p className="signup-link">
                  No account ?{" "}
                  <NavLink to="/CreateSmAccount-Management">Sign up</NavLink>
                </p>
              </div>
            )}
            <div>
              <p className="signup-link">
                <NavLink to="/forget-pass">Forgot password ? </NavLink>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
