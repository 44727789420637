const TopMembersData = [
  {
    name: 'Etaferahu Andarge',
    title: 'Founder & Executive Board Chairman',
    image: '/assets/Teams/ET-2.jpg',
    twitter: 'https://twitter.com',
    instagram: 'https://instagram.com',
    linkedin: 'https://linkedin.com',
  },
  {
    name: 'Getabalew Ayalkibet',
    title: 'Executive Board Members',
    image: '/assets/Teams/GZ-1.jpg',
    twitter: 'https://twitter.com',
    instagram: 'https://instagram.com',
    linkedin: 'https://linkedin.com',
  },
  {
    name: 'Wubamlak Mekonen',
    title: 'Executive Board Members',
    image: '/assets/Teams/WA-1.jpg',
    twitter: 'https://twitter.com',
    instagram: 'https://instagram.com',
    linkedin: 'https://linkedin.com',
  },

]
export default TopMembersData;