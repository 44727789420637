import React from 'react'
import "./mpgsCommingsoon.css"

const MpgsCommingsoon = () => {
    return (
        <div className="Mpgsvisa-card">
            <div className="MpgslogoContainer">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="23"
                    height="23"
                    viewBox="0 0 48 48"
                    className="MpgssvgLogo"
                >
                    <path
                        fill="#ff9800"
                        d="M32 10A14 14 0 1 0 32 38A14 14 0 1 0 32 10Z"
                    ></path>
                    <path
                        fill="#d50000"
                        d="M16 10A14 14 0 1 0 16 38A14 14 0 1 0 16 10Z"
                    ></path>
                    <path
                        fill="#ff3d00"
                        d="M18,24c0,4.755,2.376,8.95,6,11.48c3.624-2.53,6-6.725,6-11.48s-2.376-8.95-6-11.48 C20.376,15.05,18,19.245,18,24z"
                    ></path>
                </svg>
            </div>
            <div className='commingsoonheader'><h3 className='commingSoon'>Samrawit Foundation</h3></div>

            <div className="Mpgsnumber-container">
                <div className='commingsoonheader'><h5 className='commingSoon'>Mastercard Donations Comming Soon !</h5></div>
                <div className='commingsoonheader'><h5 className='commingSoon'>Stay Tuned</h5></div>


            </div>

            <div className="Mpgsname-date-cvv-container">
                <div className="Mpgsname-wrapper">
                    <label className="Mpgsinput-label" htmlFor="holderName">CARD HOLDER</label>
                    <input
                        className="Mpgsinputstyle"
                        id="MpgsholderName"
                        placeholder="NAME"
                        type="text"
                    />
                </div>

                <div className="Mpgsexpiry-wrapper">
                    <label className="Mpgsinput-label" htmlFor="expiry">VALID THRU</label>
                    <input className="Mpgsinputstyle" id="Mpgsexpiry" placeholder="MM/YY" type="text" />
                </div>
                <div className="Mpgscvv-wrapper">
                    <label className="Mpgsinput-label" htmlFor="cvv">CVV</label>
                    <input
                        className="Mpgsinputstyle"
                        placeholder="***"
                        maxLength="3"
                        id="Mpgscvv"
                        type="password"
                    />
                </div>
            </div>
        </div>

    )
}

export default MpgsCommingsoon