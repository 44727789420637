import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Gallaries from './pages/Gallaries/Gallaries'
import Samrawit from './pages/Samrawit'
import Foundation from "./pages/Foundation/Thefoundation";
import About from "./pages/About/Us";
import DonatePage from "./pages/Donate/DonatePage";
import Teams from "./components/teams/Teams";
import Services from "./pages/Services/OurServices";
// admin page
import AdminPage from "./admin/admin";
import Users from './admin/adminComponents/User/Users';
import AdminHomepage from './admin/adminComponents/homepage/AdminHomepage';
import AdminFoundationPage from './admin/adminComponents/AdminFoundation/AdminFoundationPage';
import AdminAboutPage from './admin/adminComponents/About/AdminAboutPage';
import AdminservicePage from './admin/adminComponents/AdminService/AdminservicePage';
import AdminContactPage from './admin/adminComponents/AdminContact/AdminContactPage';
import AdminDonatePage from './admin/adminComponents/AdminDonate/AdminDonatePage';
import AdminTeamsPage from './admin/adminComponents/Adminteams/AdminTeamsPage';
import AdminFooterpage from './admin/adminComponents/AdminFooter/AdminFooterpage';
import AdminGallaries from './admin/adminComponents/GallariesPic/AdminGallery';
import Login from "./admin/AdminLogin/Login";
import ResetPasswordForm from "./admin/AdminLogin/ResetPasswordForm";
import ForgotPasswordForm from "./admin/AdminLogin/ForgotPasswordForm";
import CreateSmAccountComponent from "./admin/AdminLogin/CreateSmAccount";
import AdminSliderComponent from './admin/adminComponents/AdminSlider/AdminSlider'
// login Security
import AuthProvider from "./admin/global/Auth/AuthProvider";
import PrivateRoute from "./admin/global/router/PrivateRoute";



function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/" exact element={<Samrawit />}></Route>
          <Route path="/galleries" element={<Gallaries />}></Route>
          <Route path="/foundation" element={<Foundation />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/DonatePage" element={<DonatePage />}></Route>
          <Route path="/teams" element={<Teams />}></Route>
          <Route path="/services" element={<Services />}></Route>


          {/* Admin Routes */}
          <Route path="/login" element={<Login />} ></Route>
          <Route path="/CreateSmAccount-Management" element={<CreateSmAccountComponent />}></Route>
          <Route><Route path="/forget-pass" element={<ForgotPasswordForm />} /></Route>
          <Route><Route path="/reset-pass" element={<ResetPasswordForm />} /></Route>
          <Route element={<PrivateRoute />}> <Route path="/AdminPage" element={<AdminPage />} /> </Route>
          <Route element={<PrivateRoute />}> <Route path="/Users-Management" element={<Users />} /> </Route>
          <Route element={<PrivateRoute />}><Route path="/adminHomepage-Management" element={<AdminHomepage />} /> </Route>
          <Route element={<PrivateRoute />}><Route path="/AdminFoundationPage-Management" element={<AdminFoundationPage />} /></Route>
          <Route element={<PrivateRoute />}><Route path="/AdminAboutPage-Management" element={<AdminAboutPage />} /></Route>
          <Route element={<PrivateRoute />}><Route path="/AdminservicePage-Management" element={<AdminservicePage />} /></Route>
          <Route element={<PrivateRoute />}><Route path="/AdminDonatePage-Management" element={<AdminDonatePage />} /></Route>
          <Route element={<PrivateRoute />}><Route path="/AdminContactPage-Management" element={<AdminContactPage />} /></Route>
          <Route element={<PrivateRoute />}><Route path="/AdminTeamsPage-Management" element={<AdminTeamsPage />} /></Route>
          <Route element={<PrivateRoute />}><Route path="/AdminFooterpage-Management" element={<AdminFooterpage />} /></Route>
          <Route element={<PrivateRoute />}><Route path="/AdminGallaries-Management" element={<AdminGallaries />} /></Route>
          <Route element={<PrivateRoute />}><Route path="/AdminSlider-Management" element={<AdminSliderComponent />} /></Route>
        </Routes>
      </AuthProvider>
    </>
  );
}


export default App;
