const MiddleMembers = [
   {
      name: 'Tagel Nigatu Kebede (Engineer)',
      title: 'Founder & Executive Director',
      image: '/assets/family/father.jpg',
      twitter: 'https://twitter.com',
      instagram: 'https://instagram.com',
      linkedin: 'https://linkedin.com',
   },
   {
      name: 'Letikat Kinde',
      title: 'Finance Manager',
      image: '/assets/Teams/LK-1.jpg',
      twitter: 'https://twitter.com',
      instagram: 'https://instagram.com',
      linkedin: 'https://linkedin.com',
   },
   {
      name: 'Getabalew Ayalkibet',
      title: 'Program Coordinator',
      image: '/assets/Teams/GT-1.jpg',
      twitter: 'https://twitter.com',
      instagram: 'https://instagram.com',
      linkedin: 'https://linkedin.com',
   },
]

export default MiddleMembers;