import React from 'react'
import UploadPictureComponent from './UploadPicture'
import ShowImageComponent from './ShowImage'
import Sidebar from '../../global/Sidebar'
import TopBar from '../../global/TopBar'
import GalleryLanding from './GalleryLanding'
import Footer from "../../../components/footer/Footer";



const AdminGallerys = () => {
  return (
    <div className='userStatus'>
      <div className="Adminheader">
        <TopBar/>
      </div>
      <div className="sidebar_and_Page" style={{height:"fit-content"}}>
        <div className="sidebar" style={{backgroundColor:"#21232d", height:"100%"}}>
          <Sidebar/>
        </div>

        <div className="adminpageS container Admincenter ">
          <div className="foutcardRows">
            <GalleryLanding/>
            
          </div>

          <div className="Gallarycontainer container" >
            <UploadPictureComponent/>
            <ShowImageComponent/>
          </div>

        </div>
      </div>
      <div className="footerDIV AdminFooter"><Footer/></div>
    </div>
  )
}

export default AdminGallerys